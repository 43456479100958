// @flow
import { Cookies } from "react-cookie";
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { API_ROOT } from '../../constants/configValue';

import { getUserToken } from '../../helpers/authUtils';

import {
    RM_LIST,
    RM_INFO,
    RM_CREATE,
    RM_UPDATE,
    RM_USER_LIST,
    RM_USER_INFO,
    RM_USER_CREATE,
    RM_USER_UPDATE,
    RM_USER_TERMINATE,
    RM_USER_RESET_PASSWORD,
    RM_USER_WITHDRAW,
    RM_REMOVE,
} from '../../constants/actionTypes';


import {
    RMListSuccess,
    RMListFailed,
    RMUserListSuccess,
    RMUserListFailed,
} from './actions';


/**
 * Fetch data from given url
 * @param {*} url 
 * @param {*} options 
 */
const fetchJSON = (url, options = {}) => {
    let myUrl = API_ROOT+url;
    //console.log("ych fetchJSON @saga.js");
    //console.log(myUrl);
    return fetch(myUrl, options)
        .then(response => {
            if (!response.status === 200) {
                throw response.json();
            }
            return response.json();
        })
        .then(json => {
            return json;
        })
        .catch(error => { throw error });
}


/**
 * get RM list response
 * @param {*} param0 
 */
function* RMList({ payload: { } }) {
//console.log("RMList");
    try {

        let token = getUserToken();
        const options = {
            body: JSON.stringify({
            }),
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
                       'Authorization': 'Bearer '+token,
                }
        };

        try {
            const response = yield call(fetchJSON, '/admin/rm.list', options);
            //const response = yield call(fetchJSON, '/admin/review.terminating', options);
            if (response.success===false) {
                yield put(RMListFailed(response.message));
            } else {
                yield put(RMListSuccess(response));
            }
        } catch (error) {
            let response={};
            response.success = false;
            response.message = "unknown_error";

            switch (error.status) {
                case 500: response.message = 'Internal Server Error'; break;
                case 401: response.message = 'Invalid credentials'; break;
            }

            yield put(RMListFailed(response.message));
        }

    } catch (error) { }
}

/**
 * get RM info response
 * @param {*} param0 
 */
function* RMInfo({ payload: { user_id, email, response_receive } }) {
    try {

        let token = getUserToken();

        let options = {}
        if (user_id!==-1) {
            options = {  
                body: JSON.stringify({
                    "id":user_id

                }),
                method: 'POST',
                headers: { 'Content-Type': 'application/json',
                           'Authorization': 'Bearer '+token,
                    }
            };
        } else {
            options = {  
                body: JSON.stringify({
                    email
                }),
                method: 'POST',
                headers: { 'Content-Type': 'application/json',
                           'Authorization': 'Bearer '+token,
                    }
            };
        }


        try {
console.log(options);
            const response = yield call(fetchJSON, '/admin/rm.info', options);
            response_receive(response);
            //if (response.success===false) {
                //yield put(resetPasswordFailed(response.message));
            //} else {
                //yield put(resetPasswordSuccess(response));
            //}
        } catch (error) {
            let response={};
            response.success = false;
            response.message = "unknown_error";
/*
            let message;
            switch (error.status) {
                case 500: message = 'Internal Server Error'; break;
                case 401: message = 'Invalid credentials'; break;
                default: message = error;
            }
            //yield put(resetPasswordFailed(message));
*/
            response_receive(response);
        }

    } catch (error) { }
}

/**
 * RM Create
 * @param {*} param0
 */
function* RMCreate({ payload: { email, nickname, password, response_receive } }) {
    try {

        let token = getUserToken();

/*
        const options = {
            body: JSON.stringify({ "email": email,
                "nickname": nickname,
                "password": password
            }),
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
                       'Authorization': 'Bearer '+token,
                }
        };  
*/
        const options = {
            body: JSON.stringify({ "email": email,
                "nickname": nickname
            }),
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
                       'Authorization': 'Bearer '+token,
                }
        };  

        try {
            const response = yield call(fetchJSON, '/admin/rm.create', options);
            response_receive(response);
            //if (response.success===false) {
                //yield put(resetPasswordFailed(response.message));
            //} else {
                //yield put(resetPasswordSuccess(response));
            //}
        } catch (error) {
            let response={};
            response.success = false;
            response.message = "unknown_error";
/*
            let message;
            switch (error.status) {
                case 500: message = 'Internal Server Error'; break;
                case 401: message = 'Invalid credentials'; break;
                default: message = error;
            }
            //yield put(resetPasswordFailed(message));
*/
            response_receive(response);
        }

    } catch (error) { }
}

/**
 * RM Update
 * @param {*} param0
 */
function* RMUpdate({ payload: { user_id, nickname, withdrawal_address, response_receive } }) {
    try {

        let token = getUserToken();
        let options = {}
/*
        if (withdrawal_address==="") {
            options = {  
                body: JSON.stringify({ "id": user_id, 
                    "email": email,
                    "nickname": nickname
                }),
                method: 'POST',
                headers: { 'Content-Type': 'application/json',
                           'Authorization': 'Bearer '+token,
                    }
            };
        } else {
*/
            options = {  
                body: JSON.stringify({
                    "id": user_id,
                    "nickname": nickname,
                    "withdrawal_address": withdrawal_address
                }),
                method: 'POST',
                headers: { 'Content-Type': 'application/json',
                           'Authorization': 'Bearer '+token,
                    }
            };
        //}


console.log(options);
        try {
            const response = yield call(fetchJSON, '/admin/rm.update', options);
            response_receive(response);
            //if (response.success===false) {
                //yield put(resetPasswordFailed(response.message));
            //} else {
                //yield put(resetPasswordSuccess(response));
            //}
        } catch (error) {
            let response={};
            response.success = false;
            response.message = "unknown_error";
/*
            let message;
            switch (error.status) {
                case 500: message = 'Internal Server Error'; break;
                case 401: message = 'Invalid credentials'; break;
                default: message = error;
            }
            //yield put(resetPasswordFailed(message));
*/
            response_receive(response);
        }

    } catch (error) { }
}

/**
 * get RM User list response
 * @param {*} param0 
 */
function* RMUserList({ payload: { email, sorting } }) {
    try {

        let token = getUserToken();

        let options = {}
        if (email!=="") {
            options = {  
                body: JSON.stringify({
                    email,sorting

                }),
                method: 'POST',
                headers: { 'Content-Type': 'application/json',
                           'Authorization': 'Bearer '+token,
                    }
            };
        } else {
            options = {  
                body: JSON.stringify({
                    sorting
                }),
                method: 'POST',
                headers: { 'Content-Type': 'application/json',
                           'Authorization': 'Bearer '+token,
                    }
            };
        }

//console.log(options);

        try {
            const response = yield call(fetchJSON, '/admin/rm.user.list', options);
//console.log(response);
            if (response.success===false) {
                yield put(RMUserListFailed(response.message));
                //response.users = [];
                //yield put(RMUserListSuccess(response));
            } else {
                yield put(RMUserListSuccess(response));
            }
        } catch (error) {
            let response={};
            response.success = false;
            response.message = "unknown_error";

            switch (error.status) {
                case 500: response.message = 'Internal Server Error'; break;
                case 401: response.message = 'Invalid credentials'; break;
            }

            yield put(RMUserListFailed(response.message));
        }

    } catch (error) { }
}

/**
 * get RM User info response
 * @param {*} param0 
 */
function* RMUserInfo({ payload: { user_id, email, response_receive } }) {
    try {

        let token = getUserToken();

        let options = {}
        if (user_id!==-1) {
            options = {  
                body: JSON.stringify({
                    "user_id":user_id

                }),
                method: 'POST',
                headers: { 'Content-Type': 'application/json',
                           'Authorization': 'Bearer '+token,
                    }
            };
        } else {
            options = {  
                body: JSON.stringify({
                    email
                }),
                method: 'POST',
                headers: { 'Content-Type': 'application/json',
                           'Authorization': 'Bearer '+token,
                    }
            };
        }


        try {
            const response = yield call(fetchJSON, '/admin/rm.user.info', options);
            response_receive(response);
            //if (response.success===false) {
                //yield put(resetPasswordFailed(response.message));
            //} else {
                //yield put(resetPasswordSuccess(response));
            //}
        } catch (error) {
            let response={};
            response.success = false;
            response.message = "unknown_error";
/*
            let message;
            switch (error.status) {
                case 500: message = 'Internal Server Error'; break;
                case 401: message = 'Invalid credentials'; break;
                default: message = error;
            }
            //yield put(resetPasswordFailed(message));
*/
            response_receive(response);
        }

    } catch (error) { }
}

/**
 * RM User Create
 * @param {*} param0
 */
function* RMUserCreate({ payload: { email, nickname, password, response_receive } }) {
console.log("RMUserCreate");
    try {

        let token = getUserToken();

/*
        const options = {
            body: JSON.stringify({ "email": email,
                "nickname": nickname,
                "password": password
            }),
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
                       'Authorization': 'Bearer '+token,
                }
        };  
*/
        const options = {
            body: JSON.stringify({ "email": email,
                "nickname": nickname
            }),
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
                       'Authorization': 'Bearer '+token,
                }
        };  

console.log(options);
        try {
            const response = yield call(fetchJSON, '/admin/rm.user.create', options);
            response_receive(response);
            //if (response.success===false) {
                //yield put(resetPasswordFailed(response.message));
            //} else {
                //yield put(resetPasswordSuccess(response));
            //}
        } catch (error) {
            let response={};
            response.success = false;
            response.message = "unknown_error";
/*
            let message;
            switch (error.status) {
                case 500: message = 'Internal Server Error'; break;
                case 401: message = 'Invalid credentials'; break;
                default: message = error;
            }
            //yield put(resetPasswordFailed(message));
*/
            response_receive(response);
        }

    } catch (error) { }
}

/**
 * RM User Update
 * @param {*} param0
 */
function* RMUserUpdate({ payload: { user_id, email, nickname, password, response_receive } }) {
    try {

        let token = getUserToken();
        const options = {
            body: JSON.stringify({ "user_id": user_id, 
                "email": email,
                "nickname": nickname,
                "password": password
            }),
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
                       'Authorization': 'Bearer '+token,
                }
        };  

        try {
            const response = yield call(fetchJSON, '/admin/rm.user.update', options);
            response_receive(response);
            //if (response.success===false) {
                //yield put(resetPasswordFailed(response.message));
            //} else {
                //yield put(resetPasswordSuccess(response));
            //}
        } catch (error) {
            let response={};
            response.success = false;
            response.message = "unknown_error";
/*
            let message;
            switch (error.status) {
                case 500: message = 'Internal Server Error'; break;
                case 401: message = 'Invalid credentials'; break;
                default: message = error;
            }
            //yield put(resetPasswordFailed(message));
*/
            response_receive(response);
        }

    } catch (error) { }
}

/**
 * RM User Terminate
 * @param {*} param0
 */
function* RMUserTerminate({ payload: { user_id, subscription_id, response_receive } }) {
    try {

        let token = getUserToken();
        const options = {
            body: JSON.stringify({ "user_id": user_id, 
                "subscription_id": subscription_id
            }),
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
                       'Authorization': 'Bearer '+token,
                }
        };  

        try {
            const response = yield call(fetchJSON, '/admin/user.terminate', options);
            response_receive(response);
        } catch (error) {
            let response={};
            response.success = false;
            response.message = "unknown_error";
            response_receive(response);
        }

    } catch (error) { }
}

/**
 * RM User Reset Password
 * @param {*} param0
 */
function* RMUserResetPassword({ payload: { user_id, response_receive } }) {
    try {

        let token = getUserToken();
        const options = {
            body: JSON.stringify({ "user_id": user_id
            }),
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
                       'Authorization': 'Bearer '+token,
                }
        };  

        try {
            const response = yield call(fetchJSON, '/admin/user.reset.password', options);
            response_receive(response);
        } catch (error) {
            let response={};
            response.success = false;
            response.message = "unknown_error";
            response_receive(response);
        }

    } catch (error) { }
}

/**
 * RM User Withdraw
 * @param {*} param0
 */
function* RMUserWithdraw({ payload: { user_id, response_receive } }) {
    try {

        let token = getUserToken();
        const options = {
            body: JSON.stringify({ "user_id": user_id
            }),
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
                       'Authorization': 'Bearer '+token,
                }
        };  

        try {
            const response = yield call(fetchJSON, '/admin/user.withdraw', options);
            response_receive(response);
        } catch (error) {
            let response={};
            response.success = false;
            response.message = "unknown_error";
            response_receive(response);
        }

    } catch (error) { }
}

/**
 * RM Remove
 * @param {*} param0
 */
function* RMRemove({ payload: { id, new_rm_id, response_receive } }) {
    try {

        let token = getUserToken();
        const options = {
            body: JSON.stringify({ "id": id,
                "new_rm_id": new_rm_id
            }),
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
                       'Authorization': 'Bearer '+token,
                }
        };  

        try {
            const response = yield call(fetchJSON, '/admin/rm.remove', options);
            response_receive(response);
        } catch (error) {
            let response={};
            response.success = false;
            response.message = "unknown_error";
            response_receive(response);
        }

    } catch (error) { }
}

export function* watchRMList():any {
    yield takeEvery(RM_LIST, RMList);
}

export function* watchRMInfo():any {
    yield takeEvery(RM_INFO, RMInfo);
}

export function* watchRMCreate():any {
    yield takeEvery(RM_CREATE, RMCreate);
}

export function* watchRMUpdate():any {
    yield takeEvery(RM_UPDATE, RMUpdate);
}

export function* watchRMUserList():any {
    yield takeEvery(RM_USER_LIST, RMUserList);
}

export function* watchRMUserInfo():any {
    yield takeEvery(RM_USER_INFO, RMUserInfo);
}

export function* watchRMUserCreate():any {
    yield takeEvery(RM_USER_CREATE, RMUserCreate);
}

export function* watchRMUserUpdate():any {
    yield takeEvery(RM_USER_UPDATE, RMUserUpdate);
}

export function* watchRMUserTerminate():any {
    yield takeEvery(RM_USER_TERMINATE, RMUserTerminate);
}

export function* watchRMUserResetPassword():any {
    yield takeEvery(RM_USER_RESET_PASSWORD, RMUserResetPassword);
}

export function* watchRMUserWithdraw():any {
    yield takeEvery(RM_USER_WITHDRAW, RMUserWithdraw);
}

export function* watchRMRemove():any {
    yield takeEvery(RM_REMOVE, RMRemove);
}

function* RMSaga():any {
    yield all([
        fork(watchRMList),
        fork(watchRMInfo),
        fork(watchRMCreate),
        fork(watchRMUpdate),
        fork(watchRMUserList),
        fork(watchRMUserInfo),
        fork(watchRMUserCreate),
        fork(watchRMUserUpdate),
        fork(watchRMUserTerminate),
        fork(watchRMUserResetPassword),
        fork(watchRMUserWithdraw),
        fork(watchRMRemove),
    ]);
}

export default RMSaga;
