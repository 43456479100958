// @flow
import { Cookies } from "react-cookie";
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { API_ROOT } from '../../constants/configValue';

import { getUserToken } from '../../helpers/authUtils';

import {
    TRANSFER_FORM_LIST,
    TRANSFER_FORM_CANCEL,
    TRANSFER_FORM_CHECK,
    TRANSFER_FORM_SETTLE,
} from '../../constants/actionTypes';


import {
    transferFormListSuccess,
    transferFormListFailed,
    transferFormCheckSuccess,
    transferFormCheckFailed,
    transferFormSettleResponse,
} from './actions';


/**
 * Fetch data from given url
 * @param {*} url 
 * @param {*} options 
 */
const fetchJSON = (url, options = {}) => {
    let myUrl = API_ROOT+url;
    //console.log("ych fetchJSON @saga.js");
    //console.log(myUrl);
    return fetch(myUrl, options)
        .then(response => {
            if (!response.status === 200) {
                throw response.json();
            }
            return response.json();
        })
        .then(json => {
            return json;
        })
        .catch(error => { throw error });
}


/**
 * get transfer form list
 * @param {*} param0 
 */
function* transferFormList({ payload: { only_unfilled } }) {
    try {

        let token = getUserToken();
        const options = {
            body: JSON.stringify({
                only_unfilled
            }),
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
                       'Authorization': 'Bearer '+token,
                }
        };

        try {
            const response = yield call(fetchJSON, '/admin/transfer.form.list', options);
            if (response.success===false) {
                yield put(transferFormListFailed(response.message));
            } else {
                yield put(transferFormListSuccess(response));
            }
        } catch (error) {
            let response={};
            response.success = false;
            response.message = "unknown_error";

            switch (error.status) {
                case 500: response.message = 'Internal Server Error'; break;
                case 401: response.message = 'Invalid credentials'; break;
            }

            yield put(transferFormListFailed(response.message));
        }

    } catch (error) { }
}

/**
 * cancel form
 * @param {*} param0
 */
function* transferFormCancel({ payload: { form_id, response_receive } }) {
    try {

        let token = getUserToken();
        const options = {
            body: JSON.stringify({ "form_id": form_id
            }),
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
                       'Authorization': 'Bearer '+token,
                }
        };

        try {
            const response = yield call(fetchJSON, '/admin/transfer.form.cancel', options);
            response_receive(response);
        } catch (error) {
            let response={};
            response.success = false;
            response.message = "unknown_error";
            response_receive(response);
        }

    } catch (error) { }
}

/**
 * get transfer form check
 * @param {*} param0 
 */
function* transferFormCheck({ payload: { form_id } }) {
    try {

        let token = getUserToken();
        const options = {
            body: JSON.stringify({
                form_id
            }),
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
                       'Authorization': 'Bearer '+token,
                }
        };

        try {
            const response = yield call(fetchJSON, '/admin/transfer.form.check', options);
            if (response.success===false) {
                yield put(transferFormCheckFailed(response.message));
            } else {
                yield put(transferFormCheckSuccess(response));
            }
        } catch (error) {
            let response={};
            response.success = false;
            response.message = "unknown_error";

            switch (error.status) {
                case 500: response.message = 'Internal Server Error'; break;
                case 401: response.message = 'Invalid credentials'; break;
            }

            yield put(transferFormCheckFailed(response.message));
        }

    } catch (error) { }
}

/**
 * settle form
 * @param {*} param0
 */
function* transferFormSettle({ payload: { form_id, tx_hash, amount, fee, response_receive } }) {
    try {

        let token = getUserToken();
        const options = {
            body: JSON.stringify({ "form_id": form_id,
                "tx_hash": tx_hash,
                "amount": amount,
                "fee": fee,
            }),
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
                       'Authorization': 'Bearer '+token,
                }
        };

        try {
            const response = yield call(fetchJSON, '/admin/transfer.form.settle', options);
            response_receive(response);
        } catch (error) {
//console.log(error);
            let response={};
            response.success = false;
            response.message = "unknown_error";
            response_receive(response);
        }
        yield put(transferFormSettleResponse());

    } catch (error) { }
}

export function* watchTransferFormList():any {
    yield takeEvery(TRANSFER_FORM_LIST, transferFormList);
}

export function* watchTransferFormCancel():any {
    yield takeEvery(TRANSFER_FORM_CANCEL, transferFormCancel);
}

export function* watchTransferFormCheck():any {
    yield takeEvery(TRANSFER_FORM_CHECK, transferFormCheck);
}

export function* watchTransferFormSettle():any {
    yield takeEvery(TRANSFER_FORM_SETTLE, transferFormSettle);
}

function* postSaga():any {
    yield all([
        fork(watchTransferFormList),
        fork(watchTransferFormCancel),
        fork(watchTransferFormCheck),
        fork(watchTransferFormSettle),
    ]);
}

export default postSaga;
