import React from 'react';
import { Redirect } from "react-router-dom";
import { Route } from 'react-router-dom';

import { isUserAuthenticated, getLoggedInUser } from './helpers/authUtils';

import { isUserOperator } from './helpers/authUtils';
import { isUserAdmin } from './helpers/authUtils';
import { isUserRM } from './helpers/authUtils';

// lazy load all the views
const UserMgmtAllAccounts = React.lazy(() => import('./pages/UserMgmtAllAccounts'));
const RMListPage = React.lazy(() => import('./pages/RMListPage'));
const UserListPage = React.lazy(() => import('./pages/UserListPage'));
const RMCreateNewPage = React.lazy(() => import('./pages/RMCreateNewPage'));
const RMUpdateUserPage = React.lazy(() => import('./pages/RMUpdateUserPage'));
const FundWithdrawPage = React.lazy(() => import('./pages/FundWithdrawPage'));
const RMUserListPage = React.lazy(() => import('./pages/RMUserListPage'));
const RMUserDetailUserPage = React.lazy(() => import('./pages/RMUserDetailUserPage'));
const RMUserCreateNewPage = React.lazy(() => import('./pages/RMUserCreateNewPage'));
const FundProfitPage = React.lazy(() => import('./pages/FundProfitPage'));

const Dashboard = React.lazy(() => import('./pages/Dashboard'));
const UserMgmt = React.lazy(() => import('./pages/UserMgmt'));
const UserDetail = React.lazy(() => import('./pages/UserDetail'));
const UserResetPassword = React.lazy(() => import('./pages/UserResetPassword'));
const ReportWOTA = React.lazy(() => import('./pages/Report-WOTA'));
// const Report100EP = React.lazy(() => import('./pages/Report-100EP'));
const TransferDeposit = React.lazy(() => import('./pages/TransferDeposit'));
const TransferWithdraw = React.lazy(() => import('./pages/TransferWithdraw'));
const GenerateForm = React.lazy(() => import('./pages/GenerateForm'));
const RegisterRecent = React.lazy(() => import('./pages/RegisterRecent'));
const DepositRecent = React.lazy(() => import('./pages/DepositRecent'));
const WithdrawRecent = React.lazy(() => import('./pages/WithdrawRecent'));
const DepositFromWota = React.lazy(() => import('./pages/DepositFromWota'));
const FormPost = React.lazy(() => import('./pages/FormPost'));
const BulkEmail = React.lazy(() => import('./pages/BulkEmail'));

// lazy load id format views. 
const Form = React.lazy(() => import('./pages/Form'));
const User = React.lazy(() => import('./pages/User'));

// auth
const Login = React.lazy(() => import('./pages/auth/Login'));
const Logout = React.lazy(() => import('./pages/auth/Logout'));
const ForgetPassword = React.lazy(() => import('./pages/account/ForgetPassword'));
const Register = React.lazy(() => import('./pages/account/Register'));
const ConfirmAccount = React.lazy(() => import('./pages/account/Confirm'));

const ResetPassword = React.lazy(() => import('./pages/account/ResetPassword'));
const AccountMgmt = React.lazy(() => import('./pages/account/AccountMgmt'));

// statistics
const Stats = React.lazy(() => import('./pages/log/Stats.js'));

// handle auth and authorization

const PrivateRoute = ({ component: Component, roles, ...rest }) => (
  <Route {...rest} render={props => {
    const isAuthTokenValid = isUserAuthenticated();
    if (!isAuthTokenValid) {
      // not logged in so redirect to login page with the return url
      return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    }

    const loggedInUser = getLoggedInUser();
    // check if route is restricted by role
//console.log(roles);
//console.log(loggedInUser.role);
//console.log("ych");
    if (roles && roles.indexOf(loggedInUser.role) === -1) {
      // role not authorised so redirect to home page
      if (isUserOperator()) {
          return <Redirect to={{ pathname: '/rm/userlist' }} />
      } 
      if (isUserRM()) {
          return <Redirect to={{ pathname: '/rmuser/list' }} />
      } 
      return <Redirect to={{ pathname: '/' }} />
    }

    // authorised so return component
    return <Component {...props} />
  }} />
)

const routes = [






  // auth and account
  { path: '/login', name: 'Login', component: Login, route: Route },
  { path: '/logout', name: 'Logout', component: Logout, route: Route },
  { path: '/forget-password', name: 'Forget Password', component: ForgetPassword, route: Route },
//   { path: '/register', name: 'Register', component: Register, route: Route },
  { path: '/confirm', name: 'Confirm', component: ConfirmAccount, route: Route },

  { path: '/account', name: 'Account Management', component: AccountMgmt, route: Route },
  { path: '/reset-password', name: 'Reset Password', component: ResetPassword, route: Route },








  // other pages
  { path: '/allaccounts', exact: true, name: 'UserMgmtAllAccounts', component: UserMgmtAllAccounts, route: PrivateRoute, roles: ['operator', 'admin'], title: 'All Accounts' },
  { path: '/rm/list', name: 'RMListPage', component: RMListPage, route: PrivateRoute, roles: ['operator'], title: 'RM List' },
  { path: '/fund/withdraw', name: 'FundWithdrawPage', component: FundWithdrawPage, route: PrivateRoute, roles: ['operator'], title: 'Fund Withdraw' },
  { path: '/fund/profit', name: 'FundProfitPage', component: FundProfitPage, route: PrivateRoute, roles: ['operator'], title: 'Profit' },
  { path: '/rm/userlist', name: 'UserListPage', component: UserListPage, route: PrivateRoute, roles: ['operator'], title: 'User List' },
  { path: '/rm/create', exact: true, name: 'RMCreateNewPage', component: RMCreateNewPage, route: PrivateRoute, roles: ['operator'], title: 'Create New RM' },
  { path: '/rm/user/:id', name: 'RMUpdateUserPage', component: RMUpdateUserPage, route: PrivateRoute, roles: ['operator', 'rm'], title: 'Update RM User', },
  { path: '/rmuser/list', name: 'RMUserListPage', component: RMUserListPage, route: PrivateRoute, roles: ['rm'], title: 'RM User List' },
  { path: '/rmuser/user/:id', name: 'RMUserDetailUserPage', component: RMUserDetailUserPage, route: PrivateRoute, roles: ['operator','rm'], title: 'RM User (Single) Detail Info', },
  { path: '/rmuser/create', exact: true, name: 'RMUserCreateNewPage', component: RMUserCreateNewPage, route: PrivateRoute, roles: ['rm'], title: 'Create New User' },


  { path: '/dashboard', name: 'Dashboard', component: Dashboard, route: PrivateRoute, roles: ['admin','auditor'], title: 'Dashboard' },
  { path: '/user/:id', name: 'User', component: User, route: PrivateRoute, roles: ['admin','operator'], title: 'User Management', },
  { path: '/reset_user_password', exact: true, name: 'UserResetPassword', component: UserResetPassword, route: PrivateRoute, roles: ['admin','operator'], title: 'Reset Password' },
  { path: '/user', exact: true, name: 'UserMgmt', component: UserMgmt, route: PrivateRoute, roles: ['admin','operator'], title: 'User Assets' },
  { path: '/userdetail', exact: true, name: 'UserDetail', component: UserDetail, route: PrivateRoute, roles: ['admin','operator'], title: 'User Detail(SAMPLE)' },
  { path: '/bulkemail', exact: true, name: 'BulkEmail', component: BulkEmail, route: PrivateRoute, roles: ['admin','operator'], title: 'Bulk Email' },
  { path: '/report/wota', name: 'ReportWOTA', component: ReportWOTA, route: PrivateRoute, roles: ['admin','operator'], title: 'Report: WOTA' },
  // { path: '/report/100ep', name: 'Report100EP', component: Report100EP, route: PrivateRoute, roles: ['admin','operator'], title: 'Report: 100ETH Plan' },
  { path: '/transfer/deposit', name: 'TransferDeposit', component: TransferDeposit, route: PrivateRoute, roles: ['admin','operator'], title: 'Transfer to Wota' },
  { path: '/transfer/withdraw', name: 'TransferWithdraw', component: TransferWithdraw, route: PrivateRoute, roles: ['admin','operator'], title: 'Transfer from Wota' },
  { path: '/generate_form', exact: true, name: 'GenerateForm', component: GenerateForm, route: PrivateRoute, roles: ['admin','operator'], title: 'Generate Form' },
  { path: '/register/recent', name: 'RegisterRecent', component: RegisterRecent, route: PrivateRoute, roles: ['admin','operator'], title: 'Recent Registrations' },
  { path: '/deposit/recent', name: 'DepositRecent', component: DepositRecent, route: PrivateRoute, roles: ['admin','operator'], title: 'Recent Deposits' },
  { path: '/withdraw/recent', name: 'WithdrawRecent', component: WithdrawRecent, route: PrivateRoute, roles: ['admin','operator'], title: 'Recent Withdrawals' },
  { path: '/withdraw/deposit_from_wota', name: 'DepositFromWota', component: DepositFromWota, route: PrivateRoute, roles: ['admin','operator'], title: 'Deposit From Wota' },
  { path: '/form/:id', name: 'Form', component: Form, route: PrivateRoute, roles: ['admin','operator'], title: 'Form Management' },
  { path: '/form', exact: true, name: 'FormPost', component: FormPost, route: PrivateRoute, roles: ['admin','operator'], title: 'Write Off/Post' },

  // Statistics pages
  { path: '/stats', exact: true, name: 'Stats', component: Stats, route: Route },


  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/dashboard" />,
    route: PrivateRoute
  },
  
]

export { routes, PrivateRoute };
