// @flow
import {
    FUND_REVIEW_TERMINATING,
    FUND_REVIEW_TERMINATING_SUCCESS,
    FUND_REVIEW_TERMINATING_FAILED,
    FUND_RESUME_SUBSCRIPTION,
} from '../../constants/actionTypes';


const INIT_STATE = {
    loading: false
};

type FundAction = { type: string, payload: {} | string };
type State = { loading?: boolean, +value?: boolean };

const Fund = (state:State = INIT_STATE, action: FundAction) => {
    switch (action.type) {
        case FUND_REVIEW_TERMINATING:
            return { ...state, loading: true };
        case FUND_REVIEW_TERMINATING_SUCCESS:
            return { ...state, FundReviewTerminatingResponse: action.payload, loading: false, error: null };
        case FUND_REVIEW_TERMINATING_FAILED:
            return { ...state, error: action.payload, loading: false };
        case FUND_RESUME_SUBSCRIPTION:
            return { ...state, loading: false };
        default: return { ...state };
    }
}

export default Fund;

