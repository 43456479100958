/* AUTH */
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILED = 'LOGIN_USER_FAILED';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAILED = 'REGISTER_USER_FAILED';
export const LOGOUT_USER = 'LOGOUT_USER';
export const FORGET_PASSWORD = 'FORGET_PASSWORD';
export const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS';
export const FORGET_PASSWORD_FAILED = 'FORGET_PASSWORD_FAILED';

/* USER */
export const RESET_PASSWORD = 'RESET_PASSWORD';
//export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
//export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED';

export const LOGIN_HISTORY = 'LOGIN_HISTORY';
export const LOGIN_HISTORY_SUCCESS = 'LOGIN_HISTORY_SUCCESS';
export const LOGIN_HISTORY_FAILED = 'LOGIN_HISTORY_FAILED';

/* DASHBOARD */
export const USER_WALLET_SUMMARY = 'USER_WALLET_SUMMARY';
export const USER_WALLET_SUMMARY_SUCCESS = 'USER_WALLET_SUMMARY_SUCCESS';
export const USER_WALLET_SUMMARY_FAILED = 'USER_WALLET_SUMMARY_FAILED';
export const SYSTEM_WALLET_SUMMARY = 'SYSTEM_WALLET_SUMMARY';
export const SYSTEM_WALLET_SUMMARY_SUCCESS = 'SYSTEM_WALLET_SUMMARY_SUCCESS';
export const SYSTEM_WALLET_SUMMARY_FAILED = 'SYSTEM_WALLET_SUMMARY_FAILED';

export const DASHBOARD = 'DASHBOARD';
export const DASHBOARD_SUCCESS = 'DASHBOARD_SUCCESS';
export const DASHBOARD_FAILED = 'DASHBOARD_FAILED';

/* MANAGEMENT */
export const USER_ASSETS = 'USER_ASSETS';
export const USER_ASSETS_SUCCESS = 'USER_ASSETS_SUCCESS';
export const USER_ASSETS_FAILED = 'USER_ASSETS_FAILED';
export const USER_INFO = 'USER_INFO';
export const USER_INFO_SUCCESS = 'USER_INFO_SUCCESS';
export const USER_INFO_FAILED = 'USER_INFO_FAILED';
export const USER_EDIT = 'USER_EDIT';
export const USER_WALLET_LOG = 'USER_WALLET_LOG';
export const USER_WALLET_LOG_SUCCESS = 'USER_WALLET_LOG_SUCCESS';
export const USER_WALLET_LOG_FAILED = 'USER_WALLET_LOG_FAILED';

/* TRANSFER */
export const PAYABLE_LIST = 'PAYABLE_LIST';
export const PAYABLE_LIST_SUCCESS = 'PAYABLE_LIST_SUCCESS';
export const PAYABLE_LIST_FAILED = 'PAYABLE_LIST_FAILED';
export const PAYABLE_COLLECT = 'PAYABLE_COLLECT';

export const RECEIVABLE_LIST = 'RECEIVABLE_LIST';
export const RECEIVABLE_LIST_SUCCESS = 'RECEIVABLE_LIST_SUCCESS';
export const RECEIVABLE_LIST_FAILED = 'RECEIVABLE_LIST_FAILED';
export const RECEIVABLE_COLLECT = 'RECEIVABLE_COLLECT';

/* POST */
export const TRANSFER_FORM_LIST = 'TRANSFER_FORM_LIST';
export const TRANSFER_FORM_LIST_SUCCESS = 'TRANSFER_FORM_LIST_SUCCESS';
export const TRANSFER_FORM_LIST_FAILED = 'TRANSFER_FORM_LIST_FAILED';
export const TRANSFER_FORM_CANCEL = 'TRANSFER_FORM_CANCEL';
export const TRANSFER_FORM_CHECK = 'TRANSFER_FORM_CHECK';
export const TRANSFER_FORM_CHECK_SUCCESS = 'TRANSFER_FORM_CHECK_SUCCESS';
export const TRANSFER_FORM_CHECK_FAILED = 'TRANSFER_FORM_CHECK_FAILED';
export const TRANSFER_FORM_SETTLE = 'TRANSFER_FORM_SETTLE';
export const TRANSFER_FORM_SETTLE_RESPONSE = 'TRANSFER_FORM_SETTLE_RESPONSE';

/* RECENT */
export const RECENT_SIGNUPS = 'RECENT_SIGNUPS';
export const RECENT_SIGNUPS_SUCCESS = 'RECENT_SIGNUPS_SUCCESS';
export const RECENT_SIGNUPS_FAILED = 'RECENT_SIGNUPS_FAILED';
export const RECENT_DEPOSITS = 'RECENT_DEPOSITS';
export const RECENT_DEPOSITS_SUCCESS = 'RECENT_DEPOSITS_SUCCESS';
export const RECENT_DEPOSITS_FAILED = 'RECENT_DEPOSITS_FAILED';
export const RECENT_WITHDRAWALS = 'RECENT_WITHDRAWALS';
export const RECENT_WITHDRAWALS_SUCCESS = 'RECENT_WITHDRAWALS_SUCCESS';
export const RECENT_WITHDRAWALS_FAILED = 'RECENT_WITHDRAWALS_FAILED';

/* REQUEST */
export const MYETH_WITHDRAWALS = 'MYETH_WITHDRAWALS';
export const MYETH_WITHDRAWALS_SUCCESS = 'MYETH_WITHDRAWALS_SUCCESS';
export const MYETH_WITHDRAWALS_FAILED = 'MYETH_WITHDRAWALS_FAILED';

/* PROFILE */
export const PROFIT_INFO = 'PROFIT_INFO';
export const PROFIT_INFO_SUCCESS = 'PROFIT_INFO_SUCCESS';
export const PROFIT_INFO_FAILED = 'PROFIT_INFO_FAILED';
export const PROFIT_CALCULATE = 'PROFIT_CALCULATE';
export const PROFIT_CALCULATE_SUCCESS = 'PROFIT_CALCULATE_SUCCESS';
export const PROFIT_CALCULATE_FAILED = 'PROFIT_CALCULATE_FAILED';
export const PROFIT_POST = 'PROFIT_POST';
export const PROFIT_POST_SUCCESS = 'PROFIT_POST_SUCCESS';
export const PROFIT_POST_FAILED = 'PROFIT_POST_FAILED';
export const PROFIT_CLEAR_ERROR = 'PROFIT_CLEAR_ERROR';

/* RM */
export const RM_LIST = 'RM_LIST';
export const RM_LIST_SUCCESS = 'RM_LIST_SUCCESS';
export const RM_LIST_FAILED = 'RM_LIST_FAILED';
export const RM_INFO = 'RM_INFO';
export const RM_CREATE = 'RM_CREATE';
export const RM_UPDATE = 'RM_UPDATE';
export const RM_USER_LIST = 'RM_USER_LIST';
export const RM_USER_LIST_SUCCESS = 'RM_USER_LIST_SUCCESS';
export const RM_USER_LIST_FAILED = 'RM_USER_LIST_FAILED';
export const RM_USER_INFO = 'RM_USER_INFO';
export const RM_USER_CREATE = 'RM_USER_CREATE';
export const RM_USER_UPDATE = 'RM_USER_UPDATE';
export const RM_USER_TERMINATE = 'RM_USER_TERMINATE';
export const RM_USER_RESET_PASSWORD = 'RM_USER_RESET_PASSWORD';
export const RM_USER_WITHDRAW = 'RM_USER_WITHDRAW';
export const RM_REMOVE = 'RM_REMOVE';

/* FUND */
export const FUND_REVIEW_TERMINATING = 'FUND_REVIEW_TERMINATING';
export const FUND_REVIEW_TERMINATING_SUCCESS = 'FUND_REVIEW_TERMINATING_SUCCESS';
export const FUND_REVIEW_TERMINATING_FAILED = 'FUND_REVIEW_TERMINATING_FAILED';
export const FUND_RESUME_SUBSCRIPTION = 'FUND_RESUME_SUBSCRIPTION';
