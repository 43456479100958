// @flow
import {
    FUND_REVIEW_TERMINATING,
    FUND_REVIEW_TERMINATING_SUCCESS,
    FUND_REVIEW_TERMINATING_FAILED,
    FUND_RESUME_SUBSCRIPTION,
} from '../../constants/actionTypes';

type FundAction = { type: string, payload: {} | string };

export const FundReviewTerminating = (): FundAction => ({
    type: FUND_REVIEW_TERMINATING,
    payload: { }
});

export const FundReviewTerminatingSuccess = (FundReviewTerminatingResponse: any): FundAction => ({
    type: FUND_REVIEW_TERMINATING_SUCCESS,
    payload: FundReviewTerminatingResponse
});

export const FundReviewTerminatingFailed = (error: string): FundAction => ({
    type: FUND_REVIEW_TERMINATING_FAILED,
    payload: error
});

export const FundResumeSubscription = (user_id: int, subscription_id: int, response_receive:any): FundAction => ({
    type: FUND_RESUME_SUBSCRIPTION,
    payload: { user_id, subscription_id, response_receive }
});

