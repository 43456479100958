import {Translations_en} from './translation_en.js';
//import {Translations_kr} from './translation_kr.js';

export function Translations(language) { 
  if ((language === 'EN')||(language === 'GB')) 
  { 
    return Translations_en();
    //return Translations_kr();
  } else if (language === 'KR') 
  { 
    //return Translations_kr();
    return Translations_en();
  } else {
    return Translations_en();
  } 
} 



