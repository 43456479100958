// @flow
import {
    RM_LIST,
    RM_LIST_SUCCESS,
    RM_LIST_FAILED,
    RM_INFO,
    RM_CREATE,
    RM_UPDATE,
    RM_USER_LIST,
    RM_USER_LIST_SUCCESS,
    RM_USER_LIST_FAILED,
    RM_USER_INFO,
    RM_USER_CREATE,
    RM_USER_UPDATE,
    RM_USER_TERMINATE,
    RM_USER_RESET_PASSWORD,
    RM_USER_WITHDRAW,
    RM_REMOVE,
} from '../../constants/actionTypes';

type RMAction = { type: string, payload: {} | string };

export const RMList = (): RMAction => ({
    type: RM_LIST,
    payload: { }
});

export const RMListSuccess = (RMListResponse: any): RMAction => ({
    type: RM_LIST_SUCCESS,
    payload: RMListResponse
});

export const RMListFailed = (error: string): RMAction => ({
    type: RM_LIST_FAILED,
    payload: error
});

export const RMInfo = (user_id: int, email: string, response_receive:any): RMAction => ({
    type: RM_INFO,
    payload: { user_id, email, response_receive }
});

export const RMCreate = (email: string, nickname: string, password:string, response_receive:any): RMAction => ({
    type: RM_CREATE,
    payload: { email, nickname, password, response_receive }
});

export const RMUpdate = (user_id: int, nickname: string, withdrawal_address:string, response_receive:any): RMAction => ({
    type: RM_UPDATE,
    payload: { user_id, nickname, withdrawal_address, response_receive }
});

export const RMUserList = (email: string, sorting: string ): RMAction => ({
    type: RM_USER_LIST,
    payload: { email, sorting }
});

export const RMUserListSuccess = (RMUserListResponse: any): RMAction => ({
    type: RM_USER_LIST_SUCCESS,
    payload: RMUserListResponse
});

export const RMUserListFailed = (error: string): RMAction => ({
    type: RM_USER_LIST_FAILED,
    payload: error
});

export const RMUserInfo = (user_id: int, email: string, response_receive:any): RMAction => ({
    type: RM_USER_INFO,
    payload: { user_id, email, response_receive }
});

export const RMUserCreate = (email: string, nickname: string, password:string, response_receive:any): RMAction => ({
    type: RM_USER_CREATE,
    payload: { email, nickname, password, response_receive }
});

export const RMUserUpdate = (user_id: int, email: string, nickname: string, password:string, response_receive:any): RMAction => ({
    type: RM_USER_UPDATE,
    payload: { user_id, email, nickname, password, response_receive }
});

export const RMUserTerminate = (user_id: int, subscription_id: int, response_receive:any): RMAction => ({
    type: RM_USER_TERMINATE,
    payload: { user_id, subscription_id, response_receive }
});

export const RMUserResetPassword = (user_id: int, response_receive:any): RMAction => ({
    type: RM_USER_RESET_PASSWORD,
    payload: { user_id, response_receive }
});

export const RMUserWithdraw = (user_id: int, response_receive:any): RMAction => ({
    type: RM_USER_WITHDRAW,
    payload: { user_id, response_receive }
});

export const RMRemove = (id: int, new_rm_id: int, response_receive:any): RMAction => ({
    type: RM_REMOVE,
    payload: { id, new_rm_id, response_receive }
});

