// @flow
import { Cookies } from "react-cookie";
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { API_ROOT } from '../../constants/configValue';

import { getUserToken } from '../../helpers/authUtils';

import {
    FUND_REVIEW_TERMINATING,
    FUND_RESUME_SUBSCRIPTION,
} from '../../constants/actionTypes';


import {
    FundReviewTerminatingSuccess,
    FundReviewTerminatingFailed,
} from './actions';


/**
 * Fetch data from given url
 * @param {*} url 
 * @param {*} options 
 */
const fetchJSON = (url, options = {}) => {
    let myUrl = API_ROOT+url;
    //console.log("ych fetchJSON @saga.js");
    //console.log(myUrl);
    return fetch(myUrl, options)
        .then(response => {
            if (!response.status === 200) {
                throw response.json();
            }
            return response.json();
        })
        .then(json => {
            return json;
        })
        .catch(error => { throw error });
}


/**
 * get Fund Review Terminating response
 * @param {*} param0 
 */
function* FundReviewTerminating({ payload: { } }) {
//console.log("FundReviewTerminating");
    try {

        let token = getUserToken();
        const options = {
            body: JSON.stringify({
            }),
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
                       'Authorization': 'Bearer '+token,
                }
        };

        try {
            const response = yield call(fetchJSON, '/admin/review.terminating', options);
            if (response.success===false) {
                yield put(FundReviewTerminatingFailed(response.message));
            } else {
                yield put(FundReviewTerminatingSuccess(response));
            }
        } catch (error) {
            let response={};
            response.success = false;
            response.message = "unknown_error";

            switch (error.status) {
                case 500: response.message = 'Internal Server Error'; break;
                case 401: response.message = 'Invalid credentials'; break;
            }

            yield put(FundReviewTerminatingFailed(response.message));
        }

    } catch (error) { }
}

/**
 * Fund Resume Subscription
 * @param {*} param0
 */
function* FundResumeSubscription({ payload: { user_id, subscription_id, response_receive } }) {
    try {

        let token = getUserToken();
        let options = {}
/*
        if (withdrawal_address==="") {
            options = {  
                body: JSON.stringify({ "id": user_id, 
                    "email": email,
                    "nickname": nickname
                }),
                method: 'POST',
                headers: { 'Content-Type': 'application/json',
                           'Authorization': 'Bearer '+token,
                    }
            };
        } else {
*/
            options = {  
                body: JSON.stringify({
                    "user_id": user_id,
                    "subscription_id": subscription_id
                }),
                method: 'POST',
                headers: { 'Content-Type': 'application/json',
                           'Authorization': 'Bearer '+token,
                    }
            };
        //}


console.log(options);
        try {
            const response = yield call(fetchJSON, '/admin/resume.subscription', options);
console.log(response);
            response_receive(response);
            //if (response.success===false) {
                //yield put(resetPasswordFailed(response.message));
            //} else {
                //yield put(resetPasswordSuccess(response));
            //}
        } catch (error) {
            let response={};
            response.success = false;
            response.message = "unknown_error";
/*
            let message;
            switch (error.status) {
                case 500: message = 'Internal Server Error'; break;
                case 401: message = 'Invalid credentials'; break;
                default: message = error;
            }
            //yield put(resetPasswordFailed(message));
*/
            response_receive(response);
        }

    } catch (error) { }
}

export function* watchFundReviewTerminating():any {
    yield takeEvery(FUND_REVIEW_TERMINATING, FundReviewTerminating);
}

export function* watchFundResumeSubscription():any {
    yield takeEvery(FUND_RESUME_SUBSCRIPTION, FundResumeSubscription);
}

function* FundSaga():any {
    yield all([
        fork(watchFundReviewTerminating),
        fork(watchFundResumeSubscription),
    ]);
}

export default FundSaga;
